<template>
    <div>
        <div class="ad__videos" v-if="this.car.mini_review && this.car.mini_review.videos && this.car.mini_review.videos.length > 0">
            <h2>Video Reviews</h2>
            <div class="ad__videos_carousel ad__videos_carousel">
                <swiper class="swiper" :options="swiperOption">
                    <video-tile  v-for="(video, index) in this.car.mini_review.videos" :key="index" :video="video"></video-tile>
                    <div class="swiper-pagination" slot="pagination"></div>
                    <div class="swiper-button-prev" slot="button-prev"></div>
                    <div class="swiper-button-next" slot="button-next"></div>
                </swiper>
            </div>
        </div>
        <div class="videomodal" data-id="videomodal">

            <div class="menutoggle open" data-class="closevideo">
                <span></span>
                <span></span>
                <span></span>
            </div>

            <div class="videomodal__wrapper">
                <div class="videomodal__mediawrapper">
                    <div class="videomodal__close videomodal__close--desktop" data-class="closevideo"></div>
                    <div class="media">
                        <iframe width="420" height="315" src="" data-id="modal-video"></iframe>
                    </div>
                    <p data-id="video-title"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import VideoTile from '@/components/partials/tiles/VideoTile'
    import $ from 'jquery'
    import { Swiper } from 'vue-awesome-swiper'
    import { isMobile } from 'mobile-device-detect'

    export default {
        name: "AdVideos",
        components: {
            VideoTile,
            Swiper
        },
        data() {
            return {
                swiperOption: {
                    //slidesPerView: 3,
                    //spaceBetween: 1,
                    slidesPerView: isMobile ? 1 : 3,
                    spaceBetween: isMobile ? 20 : 1,
                    slidesPerGroup: isMobile ? 1 : 3,
                    loop: true,
                    loopFillGroupWithBlank: false,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }
                }
            }
        },
        props: ['car'],
        mounted(){
            // Open / close video modal
            $('[data-class=openvideo]').click((e) => {
                e.preventDefault()
                if ($(e.currentTarget).attr('data-video-url') != undefined) {
                    $('[data-id=videomodal]').addClass('videomodal--open')
                    $('[data-id=modal-video]').attr('src', $(e.currentTarget).attr('data-video-url'))
                    $('body').addClass('static')
                } else {
                    alert('No video defined')
                }

                $('[data-id=video-title]').text($(e.currentTarget).attr('data-video-title'))
            })

            $('[data-class=closevideo]').click((e) => {
                e.preventDefault()
                $('[data-id=videomodal]').removeClass('videomodal--open')
                $('[data-id=modal-video]').attr('src', '')
                $('body').removeClass('static')
            })
        }

    }
</script>

<style lang="scss">
</style>
