<template>
    <swiper-slide class="swiper-slide">
        <div class="ad__videos_video">
            <a href="javascript:void(0)" data-class="openvideo" :data-video-url="videoUrl" :data-video-title="getTitle">
                <div class="ad__videos_image">
                    <img :src="videoImage" alt="" width="282" height="158" />
                    <div class="ad__videos_play"></div>
                </div>
            </a>
        </div>
    </swiper-slide>
</template>

<script>

    import { SwiperSlide } from 'vue-awesome-swiper'
    export default {
        name: "VideoTile",
        props: ['video'],
        components: {
            SwiperSlide
        },
        data: function() {
            return {
                modalOpen: false,
            }
        },

        computed: {
            videoImage() {
                return process.env.VUE_APP_API_URL + "/api/image-fetch/" + this.video.youtube_id;
            },
            videoUrl() {
                return 'https://youtube.com/embed/' + this.video.youtube_id;
            },
            getTitle(){
                return this.video.title;
            }
        },

        methods: {
            // openModal() {
            //     this.modalOpen = true;
            // },
            //
            // closeModal() {
            //     this.modalOpen = false;
            // }
        }
    }
</script>

<style scoped>

    /*.swiper-slide {*/
        /*display: flex;*/
        /*justify-content: center;*/
        /*align-items: center;*/
        /*text-align: center;*/
        /*font-weight: bold;*/
        /*!*font-size: 5em;*!*/
        /*!*background-color: white*!*/
    /*}*/

</style>
