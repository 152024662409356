<template>
    <div class="gallery" data-id="gallery" style="display: flex">
        <div class="menutoggle open" data-id="closegallery" @click="closeImageGallery">
            <span></span>
            <span></span>
            <span></span>
        </div>

        <div class="gallery__images">

            <div class="gallery__current">
                <div class="gallery__swiper-counter" id="swiper-pagination" ></div>

                <swiper ref="swiper" :options="swiperOptions" :controls="true" :indicatiors="false">
                    <swiper-slide v-for="(image, index) in this.getGalleryImages" :key="index" @click="checkIframe">
                        <img v-if="index==0 &&  typeof image.hasVideo === 'undefined'" :src="getGalleryImage(image.primaryURL.xl)"  class="swiper-lazy" data-class="opengallery" />
                        <img v-if="index > 0 && typeof image.hasVideo === 'undefined'" :data-src="getGalleryImage(image.primaryURL.xl)"  class="swiper-lazy" data-class="opengallery" />
                        <div v-if="typeof image.hasVideo !== 'undefined'" :style="getUrl(image)">
                            <img v-if="typeof image.hasVideo !== 'undefined'" :data-src="require('../../assets/img/image-ad-transparent.png')" class="swiper-lazy" />
                        </div>
                        <div v-if="index > 0" class="swiper-lazy-preloader" id="swiper-lazy-preloader"></div>
<!--                        <img  v-if="typeof image.hasVideo === 'undefined'" :src="image.primaryURL.xl"   />-->
<!--                        <img v-if="typeof image.hasVideo !== 'undefined'" :src="image.primaryURL.xl"  width="1040" height="780" />-->
                    </swiper-slide>

                    <!--<div class="player">show player</div>-->

                    <div class="swiper-button-prev" slot="button-prev" @click="checkIframe"></div>
                    <div class="swiper-button-next" slot="button-next" @click="checkIframe"></div>
                    <div class="player" id="player"  slot="button-next" @click="playVideo"><span class="youtube-play"></span></div>
                </swiper>

<!--                <div class="gallery__mobilecontrols">-->
<!--                    <ul>-->
<!--                        <li><a data-class="gallery-prev" slot="button-prev" class="gallery-button-prev"></a></li>-->
<!--                        <li><a data-class="gallery-next" slot="button-next" class="gallery-button-next"></a></li>-->
<!--                    </ul>-->
<!--                </div>-->
<!--                <div class="gallery__desktopcontrols">-->
<!--                    <ul>-->
<!--                        <li><a data-class="gallery-prev" slot="button-prev" class="gallery-button-prev"></a></li>-->
<!--                        <li><a data-class="gallery-next" slot="button-next" class="gallery-button-next"></a></li>-->
<!--                    </ul>-->
<!--                </div>-->
            </div>

            <div class="gallery__thumbnails">
                <div class="gallery__costs">
                    <div class="gallery__price">{{ formattedCarPrice }}</div>
                    <div class="gallery__payments" v-if="formattedMonthlyPrice != ''">Monthly payments from <span>{{ formattedMonthlyPrice }}</span></div>
                </div>
                <h1>{{ this.car.reg_year }} {{ formattedRegPrefix }} {{ typeof this.car.make === 'object' ? this.car.make.make: this.car.make}} {{ typeof this.car.model === 'object' ? this.car.model.model : this.car.model }}</h1>
                <h2>{{ this.car.variant }}</h2>
                <div class="gallery__thumbnailswrapper" data-id="gallery-thumbnailswrapper">
                    <div class="gallery__thumbnails_images" data-id="gallery-thumbnails">
                        <div v-bind:class="{ active: currentIndex == index }"  class="gallery__thumbnail" v-for="(image, index) in getGalleryImages" :id="index" :key="index" @click="slideTo(index)">
<!--                            <img :src="image.primaryURL.xs" width="119" height="90" />-->
                            <v-lazy-image v-bind:src="getImageSrc(image.primaryURL.xs)" :src-placeholder="placeholderLazyImg" v-bind:alt=index v-bind:title="index" width="119" height="90" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

<!--        TODO temporarily disabled-->
<!--        <div class="gallery__ctas">-->
<!--            <a href="#" class="button">Call</a>-->
<!--            <a href="#" class="button">SMS</a>-->
<!--            <a href="#" class="button">Email</a>-->
<!--        </div>-->
    </div>
</template>

<script>
    import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
    import VLazyImage from "v-lazy-image"
    import '../../../node_modules/swiper/css/swiper.css'
    import EventBus from "../../event-bus";
    import $ from "jquery";
    var numeral = require('numeral')

    export default {
        name: "ImageGallery",
        props: ['car'],

        components: {
            Swiper,
            SwiperSlide,
            VLazyImage
        },

        data () {
            return {
                hasVideo:false,
                currentIndex:0,
                swiperOptions: {
                    init: false,
                    loop: false,
                    slidesPerView: 'auto',
                    centeredSlides: true,
                    // autoHeight: true,
                    lazy: {
                        loadPrevNext: true
                    },
                    pagination: {
                        el: '#swiper-pagination',
                        type: 'custom',
                        renderCustom: function (swiper, current, total) {
                            return current + ' of ' + total;
                        },
                    },
                    navigation: {
                        prevEl: '.swiper-button-prev',
                        nextEl: '.swiper-button-next'
                    },
                }
            }
        },

        computed: {
            placeholderLazyImg(){
                return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";
            },
            myCar(){
              return this.car;
            },
            getGalleryImages() {
                let arr = [];
                let maxSlice = this.myCar.images.length >= 2 ? 2 : this.myCar.images.length;
                arr.push(...this.myCar.images.slice(0, maxSlice));

                if (this.isVideoAvailable) {
                    let video = {
                        'primaryURL': {
                            'xl': this.getVideoImage(),
                            'xs': this.getVideoImage()
                        },
                        'hasVideo' : true
                    };
                    arr.push(video);
                }

                if (this.myCar.images.length > 2) {
                    arr.push(...this.myCar.images.slice(2));
                }

                return arr;
            },

            isVideoAvailable() {
                return this.car.youtube_id != null ? true : false;
            },

            formattedCarPrice() {
                return this.car.price != null && this.car.price > 10 ? '€' + numeral(this.car.price).format('0,0') : 'POA';
            },

            formattedMonthlyPrice() {
                return this.car.from_monthly != null ? '€' + numeral(this.car.from_monthly).format('0,0') : '';
            },

            formattedRegPrefix() {
                return this.car.reg_prefix != null ? ' (' + this.car.reg_prefix + ')' : '';
            },
            formattedYoutubeUrl() {
                return 'https://www.youtube.com/embed/' + this.car.youtube_id + '?autoplay=0&modestbranding=1&wmode=opaque&rel=0&showinfo=1&enablejsapi=1&controls=1&version=3&playerapiid=ytplayer';
            },
        },

        mounted() {
            $(function() {
                'use strict'

                $('[data-id=gallery-thumbnailswrapper]').each((index, e) => {
                    const bounds = e.getBoundingClientRect()
                    const containerHeight = window.innerHeight - (bounds.top + 30)
                    $(e).css('height', containerHeight+'px')
                    $('body').css('overflow', 'hidden');
                })
            });

            let that = this;

            this.$refs.swiper.$swiper.on('init', function() {});

            this.$refs.swiper.$swiper.on('slideChange', function() {
                let content = that.getGalleryImages[that.$refs.swiper.$swiper.activeIndex];
                that.currentIndex = that.$refs.swiper.$swiper.activeIndex;
                // let mainElement = document.getElementById("player");
                let element = document.getElementById("player");
                if(typeof content.hasVideo !== "undefined"){
                    element.classList.add("hasVideo");
                }else{
                    element.classList.remove("hasVideo");
                }
            });

            this.$refs.swiper.$swiper.init();

        },

        methods: {
            getGalleryImage(path){
                if(path == null || path.length == 0 || path.endsWith('/advertising/')){
                    return require('../../assets/img/photos/missing/large.jpg')
                }else{
                    return this.car.dealer.id == 1 ? path + '?v='+ Math.floor(Date.now() / 1000) : path;
                }

            },
            getImageSrc(src){
                if(src.endsWith('/advertising/') == true){
                    return require('../../assets/img/photos/missing/large.jpg');
                }
                return src;
            },
            getUrl(obj) {
                let url = obj.primaryURL.xl != null ? obj.primaryURL.xl : require('../../assets/img/photos/missing/large.jpg');
                return "background-image: url('" + url + "');background-size: cover; background-repeat: no-repeat; background-position-x: center; width: 100%; position:absolute; top:0";
            },
            closeImageGallery() {
                // TODO remove this horrible hack
                $('body').css('overflow', 'unset');

                EventBus.$emit('closeImageGallery');
            },

            slideTo(index) {
                this.currentIndex = index;
                this.$refs.swiper.$swiper.slideTo(index);
            },
            getVideoImage(){
                let url;
                if (this.isVideoAvailable) {
                    url = process.env.VUE_APP_API_URL + "/api/image-fetch/" + this.car.youtube_id;
                }
                return url;
            },
            playVideo(){

                let element = document.getElementsByClassName("swiper-slide-active");
                // if(typeof element[2] !== 'undefined'){
                //     element[2].children[0].style.display='none';
                // }else{
                //     // element[1].children[0].style.display='none';
                // }

                let node = document.createElement("iframe");
                node.src = this.formattedYoutubeUrl;
                node.className = "youtube-video video-iframe";

                let containerNode = document.createElement("div");
                containerNode.className = "resp-container";
                containerNode.appendChild(node);
                if(typeof element[2] !== 'undefined'){
                    element[2].appendChild(containerNode);
                }else if(typeof element[1] !== 'undefined'){
                    element[1].appendChild(containerNode);
                }else{
                    element[0].appendChild(containerNode);
                }
                let player = document.getElementById("player");
                player.classList.remove("hasVideo");
            },
            checkIframe(){
                $('.swiper-slide-active').find('.resp-container').remove();
                let active = document.getElementsByClassName("swiper-slide-active");
                if( typeof active[1] === "undefined") {
                    if(typeof active[0] !== 'undefined'){
                        active[0].style.display = 'block';
                    }
                }else{
                    if(typeof active[1] !== 'undefined'){
                        active[1].style.display = 'block';
                    }

                }

            }
        }
    }
</script>

<style scoped>
    .youtube-play{
        /*width: 55px;*/
        height: 80px;
        width: 100%;
        background-size:80px;
        background-repeat: no-repeat;
        background-color: transparent;
        background-image: url("../../assets/img/youtube-play-button.svg");
    }

    iframe{
        width: 80% !important;
        height: 80% !important;
    }
</style>
