<template>
    <div class="infotoggle">
        <div
            v-if="showExpertReviews"
            class="toggle-header"
            :class="section1visible ? null : 'collapsed'"
            :aria-expanded="section1visible ? 'true' : 'false'"
            aria-controls="collapse1"
            @click="section1visible = !section1visible">

            <div class="toggle-header__content">
                <span v-if="section1visible">-</span>
                <span v-if="!section1visible">+</span>
                What the experts say
            </div>
        </div>
        <div id="collapse1" v-if="showExpertReviews && section1visible">
            <div>
                <!-- What the experts say -->
                    <div class="ad__quotes" v-if="this.car.expert_reviews && this.car.expert_reviews.length > 0">
                        <div class="ad__quotes_quote"  v-for="(item, index) in this.car.expert_reviews" v-bind:key="index">
                            <div class="ad__quotes_avatar" :style="expertImageUrl(item.expert.photo)"></div>
                            <div class="ad__quotes_content">
                                <h2> {{ item.expert.name }}</h2>
                                <p>{{ item.review }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="ad__minireview" v-if="this.car.expert_reviews == null">
                        Expert review not available yet.
                    </div>

            </div>
        </div>

        <div
            v-if="showMiniReviews"
            class="toggle-header"
            :class="section2visible ? null : 'collapsed'"
            :aria-expanded="section2visible ? 'true' : 'false'"
            aria-controls="collapse2"
            @click="section2visible = !section2visible">

            <div class="toggle-header__content">
                <span v-if="section2visible">-</span>
                <span v-if="!section2visible">+</span>
                Mini Review
            </div>
        </div>
        <div id="collapse2" v-if="section2visible">
            <div>
                <div class="ad__minireview" v-if="this.car.mini_review != null">
                    <div class="ad__minireview_left">
                        <h2>{{ this.car.mini_review.title }}</h2>
                        <h3>Summary</h3>
                        <div class="divsummary" v-html="this.car.mini_review.summary" />
                        <br>
                        <div class="ad__minireview_cols">
                            <div class="ad__minireview_col">
                                <h4>Pros</h4>
                                <ul>
                                    <li v-for="(pro, index) in this.car.mini_review.pros" :key="index">{{ pro.trait }}</li>
                                </ul>
                            </div>
                            <div class="ad__minireview_col">
                                <h4>Cons</h4>
                                <ul>
                                    <li v-for="(con, index) in this.car.mini_review.cons" :key="index">{{ con.trait }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="ad__minireview_right">
                        <div class="ad__minireview_ratings">
                            <dl>
                                <dt v-if="runningCostStars > 0">Running Costs:</dt>
                                <dd v-if="runningCostStars > 0">
                                    <div class="stars">
                                        <span v-for="(star, index) in runningCostStars" :key="index"></span>
                                        <span class="stars__empty" v-for="star in (5-runningCostStars)" :key="star.id"></span>
                                    </div>
                                </dd>
                                <dt v-if="reliabilityStars > 0">Reliability:</dt>
                                <dd v-if="reliabilityStars > 0">
                                    <div class="stars">
                                        <span v-for="(star, index) in reliabilityStars" :key="index"></span>
                                        <span class="stars__empty" v-for="star in (5-reliabilityStars)" :key="star.id"></span>
                                    </div>
                                </dd>
                                <dt v-if="safetyStars > 0">Safety:</dt>
                                <dd v-if="safetyStars > 0">
                                    <div class="stars">
                                        <span v-for="(star, index) in safetyStars" :key="index"></span>
                                        <span class="stars__empty" v-for="star in (5-safetyStars)" :key="star.id"></span>
                                    </div>
                                </dd>
                                <dt v-if="comfortStars > 0">Comfort:</dt>
                                <dd v-if="comfortStars > 0">
                                    <div class="stars">
                                        <span v-for="(star, index) in comfortStars" :key="index"></span>
                                        <span class="stars__empty" v-for="star in (5-comfortStars)" :key="star.id"></span>
                                    </div>
                                </dd>
                                <dt v-if="handlingStars > 0">Handling:</dt>
                                <dd v-if="handlingStars > 0">
                                    <div class="stars">
                                        <span v-for="(star, index) in handlingStars" :key="index"></span>
                                        <span class="stars__empty" v-for="star in (5-handlingStars)" :key="star.id"></span>
                                    </div>
                                </dd>
                                <dt v-if="interiorStars > 0">Interior:</dt>
                                <dd v-if="interiorStars > 0">
                                    <div class="stars">
                                        <span v-for="(star, index) in interiorStars" :key="index"></span>
                                        <span class="stars__empty" v-for="star in (5-interiorStars)" :key="star.id"></span>
                                    </div>
                                </dd>
                                <dt v-if="exteriorStars > 0">Exterior:</dt>
                                <dd v-if="exteriorStars > 0">
                                    <div class="stars">
                                        <span v-for="(star, index) in exteriorStars" :key="index"></span>
                                        <span class="stars__empty" v-for="star in (5-exteriorStars)" :key="star.id"></span>
                                    </div>
                                </dd>
                            </dl>
                        </div>
                    </div>
                </div>
                <div class="ad__minireview" v-if="this.car.mini_review == null">
                    Review not available yet.
                </div>

            </div>
        </div>
        <div
            class="toggle-header"
            :class="section3visible ? null : 'collapsed'"
            :aria-expanded="section3visible ? 'true' : 'false'"
            aria-controls="collapse3"
            @click="section3visible = !section3visible">

            <div class="toggle-header__content">
                <span v-if="section3visible">-</span>
                <span v-if="!section3visible">+</span>
                Performance Data
            </div>
        </div>
        <div id="collapse3" v-if="section3visible">
            <div>
                <div class="ad__stats">
                    <div class="ad__stats_col">
                        <ul>
                            <li><span class="ad__stats_title">0-62 MPH:</span> <span class="ad__stats_desc" v-bind:class="{ disabled: checkDisabled( formattedAcceleration ) }">{{ formattedAcceleration}}</span></li>
                            <li><span class="ad__stats_title">Top speed:</span> <span class="ad__stats_desc" v-bind:class="{ disabled: checkDisabled( formattedTopSpeed ) }">{{ formattedTopSpeed }}</span></li>
                            <li><span class="ad__stats_title">Cylinders:</span> <span class="ad__stats_desc" v-bind:class="{ disabled: checkDisabled( formattedCylinders ) }">{{ formattedCylinders }}</span></li>
                            <li><span class="ad__stats_title">Valves:</span> <span class="ad__stats_desc" v-bind:class="{ disabled: checkDisabled( formattedValves ) }">{{ formattedValves }}</span></li>
                        </ul>
                    </div>
                    <div class="ad__stats_col">
                        <ul>
                            <li><span class="ad__stats_title">Engine power:</span> <span class="ad__stats_desc" v-bind:class="{ disabled: checkDisabled( formattedEnginePower ) }">{{ formattedEnginePower }}</span></li>
                            <li><span class="ad__stats_title">Engine torque:</span> <span class="ad__stats_desc" v-bind:class="{ disabled: checkDisabled( formattedTorque ) }">{{ formattedTorque }}</span></li>
                            <li><span class="ad__stats_title">CO2:</span> <span class="ad__stats_desc" v-bind:class="{ disabled: checkDisabled( formattedCO2 ) }">{{ formattedCO2 }}</span></li>
                            <li><span class="ad__stats_title">Drive:</span> <span class="ad__stats_desc" v-bind:class="{ disabled: checkDisabled( formattedDrive ) }">{{ formattedDrive }}</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="toggle-header"
            :class="section4visible ? null : 'collapsed'"
            :aria-expanded="section4visible ? 'true' : 'false'"
            aria-controls="collapse4"
            @click="section4visible = !section4visible">

            <div class="toggle-header__content">
                <span v-if="section4visible">-</span>
                <span v-if="!section4visible">+</span>
                Running Costs
            </div>
        </div>
        <div id="collapse4" v-if="section4visible">
            <div>
                <div class="ad__stats">
                    <div class="ad__stats_col">
                        <ul>
                            <li><span class="ad__stats_title ad__stats_title--costs">Fuel Consumption (Urban):</span> <span class="ad__stats_desc" v-bind:class="{ disabled: checkDisabled( formattedFuelEconUrban ) }">{{ formattedFuelEconUrban}}</span></li>
                            <li><span class="ad__stats_title ad__stats_title--costs">Fuel Consumption (Rural):</span> <span class="ad__stats_desc" v-bind:class="{ disabled: checkDisabled( formattedFuelEconRural ) }">{{ formattedFuelEconRural }}</span></li>
                            <li><span class="ad__stats_title ad__stats_title--costs">Fuel Consumption (Combined):</span> <span class="ad__stats_desc" v-bind:class="{ disabled: checkDisabled( formattedFuelEconCombined ) }">{{ formattedFuelEconCombined }}</span></li>
                            <li><span class="ad__stats_title ad__stats_title--costs">Annual Tax:</span> <span class="ad__stats_desc" v-bind:class="{ disabled: checkDisabled( formattedRoadTax ) }">{{ formattedRoadTax }}</span></li>
                            <li><span class="ad__stats_title ad__stats_title--costs">Cost to Fill Tank:</span> <span class="ad__stats_desc" v-bind:class="{ disabled: checkDisabled( formattedCostToFillTank ) }">{{ formattedCostToFillTank }}</span></li>
                        </ul>
                    </div>
                    <div class="ad__stats_col">
                        <ul>
                            <li><span class="ad__stats_title ad__stats_title--costs">100 miles of Urban Driving:</span> <span class="ad__stats_desc" v-bind:class="{ disabled: checkDisabled( formattedUrban100milesCost ) }">{{ formattedUrban100milesCost }}</span></li>
                            <li><span class="ad__stats_title ad__stats_title--costs">100 miles of Rural Driving:</span> <span class="ad__stats_desc" v-bind:class="{ disabled: checkDisabled( formattedRural100milesCost ) }">{{ formattedRural100milesCost }}</span></li>
                            <li><span class="ad__stats_title ad__stats_title--costs">100 miles of Combined Driving:</span> <span class="ad__stats_desc" v-bind:class="{ disabled: checkDisabled( formattedCombined100milesCost ) }">{{ formattedCombined100milesCost }}</span></li>
                            <li><span class="ad__stats_title ad__stats_title--costs">Main Service:</span> <span class="ad__stats_desc" v-bind:class="{ disabled: checkDisabled( formattedMainService ) }">{{ formattedMainService }}</span></li>
                            <li><span class="ad__stats_title ad__stats_title--costs">Intermediate Service:</span> <span class="ad__stats_desc" v-bind:class="{ disabled: checkDisabled( formattedIntermediateService ) }">{{formattedIntermediateService}}</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="toggle-header"
            :class="section5visible ? null : 'collapsed'"
            :aria-expanded="section5visible ? 'true' : 'false'"
            aria-controls="collapse5"
            @click="section5visible = !section5visible">

            <div class="toggle-header__content">
                <span v-if="section5visible">-</span>
                <span v-if="!section5visible">+</span>
                Safety Information
            </div>
        </div>
        <div id="collapse5" v-if="section5visible">
            <div>
                <div class="ad__safetyinfo">
                    <h2>Euro NCAP Safety Ratings</h2>
                    <dl>
                        <dl>
                            <dt>Adult Occupant</dt>
                            <dd>
                                <span v-if="checkDisabled( ncapAdult )" v-bind:class="{ disabled: checkDisabled( ncapAdult ) }">{{ncapAdult}}</span>
                                <div class="stars stars--med" v-if="!checkDisabled( ncapAdult )">
                                    <span v-for="(star,idx) in ncapAdult" v-bind:key="idx"></span>
                                    <span class="stars__empty" v-for="(star, idx) in 5-ncapAdult" v-bind:key="idx+50"></span>
                                </div>
                            </dd>
                            <dt>Child Occupant</dt>
                            <dd>
                                <span v-if="checkDisabled( ncapChild )" v-bind:class="{ disabled: checkDisabled( ncapChild ) }">{{ncapChild}}</span>
                                <div class="stars stars--med" v-if="!checkDisabled( ncapChild )">
                                    <span v-for="(star,idx) in ncapChild" v-bind:key="idx"></span>
                                    <span class="stars__empty" v-for="(star, idx) in 5-ncapChild" v-bind:key="idx+50"></span>
                                </div>
                            </dd>
                            <dt>Vulnerable Road Users</dt>
                            <dd>
                                <span v-if="checkDisabled( formattedVulnerableRoadUsers )" v-bind:class="{ disabled: checkDisabled( formattedVulnerableRoadUsers ) }">{{formattedVulnerableRoadUsers}}</span>
                                <div class="stars stars--med" v-if="!checkDisabled( formattedVulnerableRoadUsers )">
                                    <span v-for="(star,idx) in formattedVulnerableRoadUsers" v-bind:key="idx"></span>
                                    <span class="stars__empty" v-for="(star, idx) in 5-formattedVulnerableRoadUsers" v-bind:key="idx+50"></span>
                                </div>
                            </dd>
                            <dt>Safety Assist</dt>
                            <dd>
                                <span v-if="checkDisabled( formattedSafetyAssist )" v-bind:class="{ disabled: checkDisabled( formattedSafetyAssist ) }">{{ formattedSafetyAssist }}</span>
                                <div class="stars stars--med" v-if="!checkDisabled( formattedSafetyAssist )">
                                    <span v-for="(star,idx) in formattedSafetyAssist" v-bind:key="idx"></span>
                                    <span class="stars__empty" v-for="(star, idx) in 5-formattedSafetyAssist" v-bind:key="idx+50"></span>
                                </div>
                            </dd>
                            <dt>Overall Safety Rating </dt>
                            <dd>
                                <span v-if="checkDisabled( ncapOverall )" v-bind:class="{ disabled: checkDisabled( ncapOverall ) }">{{ncapOverall}}</span>
                                <div class="stars stars--med" v-if="!checkDisabled( ncapOverall )">
                                    <span v-for="(star,idx) in ncapOverall" v-bind:key="idx"></span>
                                    <span class="stars__empty" v-for="(star, idx) in 5-ncapOverall" v-bind:key="idx+50"></span>
                                </div>
                            </dd>
                        </dl>
                    </dl>
                </div>
                <div class="ad__checklist">
                    <ul>
                        <li v-for="feature in this.car.features" :key="feature.abbr" >
                            <div class="ter-checklist-item">{{ feature.description }}<span></span></div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div
            class="toggle-header"
            :class="section6visible ? null : 'collapsed'"
            :aria-expanded="section6visible ? 'true' : 'false'"
            aria-controls="collapse6"
            @click="section6visible = !section6visible">

            <div class="toggle-header__content">
                <span v-if="section6visible">-</span>
                <span v-if="!section6visible">+</span>
                Car Dimensions
            </div>
        </div>
        <div id="collapse6" v-if="section6visible">
            <div>
                <div class="ad__stats">
                    <div class="ad__stats_col">
                        <ul>
                            <li><span class="ad__stats_title">Height:</span> <span class="ad__stats_desc" v-bind:class="{ disabled: checkDisabled( formattedHeight ) }">{{ formattedHeight }}</span></li>
                            <li><span class="ad__stats_title">Width:</span> <span class="ad__stats_desc" v-bind:class="{ disabled: checkDisabled( formattedWidth ) }">{{ formattedWidth }}</span></li>
                            <li><span class="ad__stats_title">Length:</span> <span class="ad__stats_desc" v-bind:class="{ disabled: checkDisabled( formattedLength ) }">{{ formattedLength }}</span></li>
                            <li><span class="ad__stats_title">Wheelbase:</span> <span class="ad__stats_desc" v-bind:class="{ disabled: checkDisabled( formattedWheelbase ) }">{{ formattedWheelbase }}</span></li>
                        </ul>
                    </div>
                    <div class="ad__stats_col">
                        <ul>
                            <li><span class="ad__stats_title">Boot Space (Seats Down):</span> <span class="ad__stats_desc" v-bind:class="{ disabled: checkDisabled( formattedBootSpaceSeatsDown ) }">{{formattedBootSpaceSeatsDown}}</span></li>
                            <li><span class="ad__stats_title">Boot Space (Seats Up):</span> <span class="ad__stats_desc" v-bind:class="{ disabled: checkDisabled( formattedBootSpaceSeatsUp ) }">{{formattedBootSpaceSeatsUp}}</span></li>
                            <li><span class="ad__stats_title">Fuel Tank Capacity:</span> <span class="ad__stats_desc" v-bind:class="{ disabled: checkDisabled( formattedFuelTankCapacity ) }">{{ formattedFuelTankCapacity }}</span></li>
                            <li><span class="ad__stats_title">Minimum Kerb Weight:</span> <span class="ad__stats_desc" v-bind:class="{ disabled: checkDisabled( formattedMinimumKerbWeight ) }">{{ formattedMinimumKerbWeight }}</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // import ExpertReview from '@/components/partials/ExpertReview'
    var moment = require('moment')
    var numeral = require('numeral')
    export default {
        name: "AdCollapsableInfo",
        components: {
            // ExpertReview
        },
        props: ['car'],

        data: function() {
            return {
                section1visible: false,
                section2visible: false,
                section3visible: false,
                section4visible: false,
                section5visible: false,
                section6visible: false,
                showMiniReviews: true,
                showExpertReviews: process.env.VUE_APP_ENV != 'production',

            }
        },
        mounted(){
        },

        computed: {
            formattedRegion() {
                return this.car.dealer != null && this.car.dealer.region != null ? this.car.dealer.region : '';
            },

            formattedUpdatedAtDate() {
                return moment(this.car.updated_at).format('YYYY-MM-DD');
            },

            formattedEngineSize() {
                return numeral(this.car.engine_cc / 1000).format('0.0');
            },

            formattedEnginePower() {
                return this.car.max_power_bhp ? numeral(this.car.max_power_bhp).format('0') + ' bhp' : 'Not available';
            },

            formattedTorque() {
                return this.car.ad_extra_info != null && this.car.ad_extra_info.max_torque_nm != null ? numeral(this.car.ad_extra_info.max_torque_nm).format('0') + ' lbs/ft' : 'Not available';
            },

            formattedFuelEconUrban() {
                let mpg = this.car.ad_extra_info != null && this.car.ad_extra_info.fuel_cons_urban != null ? numeral(this.car.ad_extra_info.fuel_cons_urban).format('0.0') + ' mpg' : '';
                let lkm = this.car.ad_extra_info != null && this.car.ad_extra_info.fuel_cons_urban_lkm != null ? numeral(this.car.ad_extra_info.fuel_cons_urban_lkm).format('0.0') + ' l/100km' : '';
                if (mpg == '' && lkm == '') {
                    return 'Not available';
                }
                return mpg + ' / ' + lkm;
            },

            formattedFuelEconRural() {
                let mpg = this.car.ad_extra_info != null && this.car.ad_extra_info.fuel_cons_extra_urban != null ? numeral(this.car.ad_extra_info.fuel_cons_extra_urban).format('0.0') + ' mpg' : '';
                let lkm = this.car.ad_extra_info != null && this.car.ad_extra_info.fuel_cons_extra_urban_lkm != null ? numeral(this.car.ad_extra_info.fuel_cons_extra_urban_lkm).format('0.0') + ' l/100km' : '';
                if (mpg == '' && lkm == '') {
                    return 'Not available';
                }
                return mpg + ' / ' + lkm;
            },

            formattedFuelEconCombined() {
                let mpg = this.car.ad_extra_info != null && this.car.ad_extra_info.fuel_cons_combined != null ? numeral(this.car.ad_extra_info.fuel_cons_combined).format('0.0') + ' mpg' : '';
                let lkm = this.car.ad_extra_info != null && this.car.ad_extra_info.fuel_cons_combined_lkm != null ? numeral(this.car.ad_extra_info.fuel_cons_combined_lkm).format('0.0') + ' l/100km' : '';
                if (mpg == '' && lkm == '') {
                    return 'Not available';
                }
                return mpg + ' / ' + lkm;
            },

            formattedRoadTax() {
                return this.car.road_tax_cost != null ? '€' + numeral(this.car.road_tax_cost).format('0') : 'Not available';
            },

            formattedCostToFillTank() {
                return this.car.cost_to_fill_tank != 0 && this.car.cost_to_fill_tank != null ? '€' + numeral(this.car.cost_to_fill_tank).format('0.00') : 'Not available';
            },

            formattedUrban100milesCost() {
                return this.car.urban_100_miles_cost != 0 && this.car.urban_100_miles_cost != null  ? '€' + numeral(this.car.urban_100_miles_cost).format('0.00') : 'Not available';
            },

            formattedRural100milesCost() {
                return this.car.rural_100_miles_cost != 0 && this.car.rural_100_miles_cost != null ? '€' + numeral(this.car.rural_100_miles_cost).format('0.00') : 'Not available';
            },

            formattedCombined100milesCost() {
                return this.car.combined_100_miles_cost != 0 && this.car.combined_100_miles_cost != null ? '€' + numeral(this.car.combined_100_miles_cost).format('0.00') : 'Not available';
            },
            formattedMainService() {
                return this.car.ad_extra_info != null && this.car.ad_extra_info.main_service != null ? this.car.ad_extra_info.main_service : 'Not available';
            },
            formattedIntermediateService(){
                return this.car.ad_extra_info != null && this.car.ad_extra_info.intermediate_service != null ? this.car.ad_extra_info.intermediate_service : 'Not available';
            },
            formattedRoadTaxDue() {
                return this.car.tax_due_date != null ? '€' + moment(this.car.tax_due_date).format('MM / YYYY') : '';
            },


            formattedAcceleration() {
                return this.car.acceleration != null ? numeral(this.car.acceleration).format('0.0') + ' seconds' : 'Not available';
            },

            formattedTopSpeed() {
                return this.car.ad_extra_info != null && this.car.ad_extra_info.max_speed_mph != null ?  numeral(this.car.ad_extra_info.max_speed_mph).format('0') + ' mph' : 'Not available';
            },

            formattedMileage() {
                return numeral(this.car.mileage).format('0,0');
            },

            formattedMileageKm() {
                return numeral(this.car.kilometres).format('0,0');
            },

            formattedNCTDue() {
                return this.car.test_due_date != null ? moment(this.car.test_due_date).format('MM/YY') : '';
            },

            formattedRegPrefix() {
                return this.car.reg_prefix != null ? ' (' + this.car.reg_prefix + ')' : '';
            },

            formattedCarPrice() {
                return numeral(this.car.price).format('0,0');
            },

            formattedMonthlyPrice() {
                return this.car.from_monthly != null ? '€' + numeral(this.car.from_monthly).format('0') : '';
            },


            formattedVulnerableRoadUsers(){
                let res = '';

                if( this.car.ad_extra_info != null && this.car.ad_extra_info.ncap_pedestrian_pct != null ){
                    res = Math.ceil(((parseInt(this.car.ad_extra_info.ncap_pedestrian_pct) * 5) / 100));
                }else if(this.car.ad_extra_info != null && this.car.ad_extra_info.ncap_pedestrian != null){
                    res = this.car.ad_extra_info.ncap_pedestrian;
                }else{
                    res = 'Not available';
                }

                return res;
            },

            formattedSafetyAssist(){
                return this.car.ad_extra_info != null && this.car.ad_extra_info.ncap_safety_pct != null ? Math.ceil(((parseInt(this.car.ad_extra_info.ncap_safety_pct) * 5) / 100)) : 'Not available';
            },

            ncapChild() {

                let res = '';

                if( this.car.ad_extra_info != null && this.car.ad_extra_info.ncap_child_pct != null ){
                    res = Math.ceil(((parseInt(this.car.ad_extra_info.ncap_child_pct) * 5) / 100));
                }else if(this.car.ad_extra_info != null && this.car.ad_extra_info.ncap_child_occupant != null){
                    res = this.car.ad_extra_info.ncap_child_occupant;
                }else{
                    res = 'Not available';
                }

                return res;
            },

            ncapAdult() {

                let res = '';

                if( this.car.ad_extra_info != null && this.car.ad_extra_info.ncap_adult_pct != null ){
                    res = Math.ceil(((parseInt(this.car.ad_extra_info.ncap_adult_pct) * 5) / 100));
                }else if(this.car.ad_extra_info != null && this.car.ad_extra_info.ncap_adult_occupant != null){
                    res = this.car.ad_extra_info.ncap_adult_occupant;
                }else{
                    res = 'Not available';
                }

                return res;

            },

            ncapOverall() {
                return this.car.ad_extra_info != null && this.car.ad_extra_info.ncap_overall != null ? Math.ceil(this.car.ad_extra_info.ncap_overall) : 'Not available';
            },

            environmental() {
                return this.car.ad_extra_info != null && this.car.ad_extra_info.co2_stars != null ? this.car.ad_extra_info.co2_stars : 0;
            },

            formattedCylinders() {
                return this.car.ad_extra_info != null && this.car.ad_extra_info.cylinders != null ? numeral(this.car.ad_extra_info.cylinders).format('0') : 'Not available';
            },

            formattedValves() {
                let cilinders = this.car.ad_extra_info != null && this.car.ad_extra_info.cylinders != null ? numeral(this.car.ad_extra_info.cylinders).format('0') : 1;
                return this.car.ad_extra_info != null && this.car.ad_extra_info.valves_per_cyl != null ? numeral(this.car.ad_extra_info.valves_per_cyl * cilinders ).format('0') : 'Not available';
            },

            formattedCO2() {
                return this.car.ad_extra_info != null && this.car.ad_extra_info.co2 != null ?  numeral(this.car.ad_extra_info.co2).format('0') + ' g/km' : 'Not available';
            },

            formattedDrive() {
                return this.car.ad_extra_info != null && this.car.ad_extra_info.drive != null ?  this.car.ad_extra_info.drive : 'Not available';
            },

            formattedHeight() {
                return this.car.ad_extra_info != null && this.car.ad_extra_info.overall_height_mm != null ?  numeral(this.car.ad_extra_info.overall_height_mm).format('0') + ' mm' : 'Not available';
            },

            formattedWidth() {
                return this.car.ad_extra_info != null && this.car.ad_extra_info.overall_width_mm != null ?  numeral(this.car.ad_extra_info.overall_width_mm).format('0') + ' mm' : 'Not available';
            },

            formattedLength() {
                return this.car.ad_extra_info != null && this.car.ad_extra_info.overall_length_mm != null ?  numeral(this.car.ad_extra_info.overall_length_mm).format('0') + ' mm' : 'Not available';
            },

            formattedWheelbase() {
                return this.car.ad_extra_info != null && this.car.ad_extra_info.wheelbase_mm != null ?  numeral(this.car.ad_extra_info.wheelbase_mm).format('0') + ' mm' : 'Not available';
            },

            formattedBootSpaceSeatsDown(){
                return this.car.ad_extra_info != null && this.car.ad_extra_info.load_comp_capacity_seats_down != null ?  numeral(this.car.ad_extra_info.load_comp_capacity_seats_down).format('0') + ' Litres' : 'Not available';
            },
            formattedBootSpaceSeatsUp(){
                return  this.car.ad_extra_info != null && this.car.ad_extra_info.load_comp_capacity != null ?  numeral(this.car.ad_extra_info.load_comp_capacity).format('0') + ' Litres' : 'Not available';
            },
            formattedFuelTankCapacity() {
                return this.car.ad_extra_info != null && this.car.ad_extra_info.fuel_tank_capacity_l != null ?  numeral(this.car.ad_extra_info.fuel_tank_capacity_l).format('0') + ' Litres' : 'Not available';
            },

            formattedMinimumKerbWeight() {
                return this.car.ad_extra_info != null && this.car.ad_extra_info.kerb_weight_kg != null ?  numeral(this.car.ad_extra_info.kerb_weight_kg).format('0') + ' Litres' : 'Not available';
            },

            mainImageUrl() {
                return this.car != null && this.car.images.length >0 && this.car.images[0].primaryURL.large != null ? "background-image: url('" + this.car.images[0].primaryURL.large + "');" : '';
            },

            image1Url() {
                return this.car != null && this.car.images.length >1 && this.car.images[1].primaryURL.large != null ? "background-image: url('" + this.car.images[1].primaryURL.large + "');" : '';
            },

            image2Url() {
                return this.car != null && this.car.images.length >2 && this.car.images[2].primaryURL.large != null ? "background-image: url('" + this.car.images[2].primaryURL.large + "');" : '';
            },

            image3Url() {
                return this.car != null && this.car.images.length >3 && this.car.images[3].primaryURL.large != null ? "background-image: url('" + this.car.images[3].primaryURL.large + "');" : '';
            },

            image4Url() {
                return this.car != null && this.car.images.length >4 && this.car.images[4].primaryURL.large != null ? "background-image: url('" + this.car.images[4].primaryURL.large + "');" : '';
            },

            runningCostStars() {
                return this.car != null && this.car.mini_review != null && this.car.mini_review.stars_running_costs != null ? Math.ceil(this.car.mini_review.stars_running_costs) : 0;
            },

            reliabilityStars() {
                return this.car != null && this.car.mini_review != null && this.car.mini_review.stars_reliability != null ? Math.ceil(this.car.mini_review.stars_reliability) : 0;
            },

            safetyStars() {
                return this.car != null && this.car.mini_review != null && this.car.mini_review.stars_safety != null ? Math.ceil(this.car.mini_review.stars_safety)  : 0;
            },

            comfortStars() {
                return this.car != null && this.car.mini_review != null && this.car.mini_review.stars_comfort != null ? Math.ceil(this.car.mini_review.stars_comfort) : 0;
            },

            handlingStars() {
                return this.car != null && this.car.mini_review != null && this.car.mini_review.stars_handling != null ? Math.ceil(this.car.mini_review.stars_handling) : 0;
            },
            interiorStars() {
                return this.car != null && this.car.mini_review != null && this.car.mini_review.stars_interior != null ? Math.ceil(this.car.mini_review.stars_interior) : 0;
            },
            exteriorStars() {
                return this.car != null && this.car.mini_review != null && this.car.mini_review.stars_exterior != null ? Math.ceil(this.car.mini_review.stars_exterior) : 0;
            }
        },
        methods:{
            checkDisabled(val) {
                return val == 'Not available';
            },
            expertImageUrl(photo) {
                return photo != null ? "background-image: url('" + process.env.VUE_APP_IMAGES_URL + '/' + photo + "');" : '';
            },
        }
    }
</script>

<style scoped>

</style>
