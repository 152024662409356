<template>
    <div>
        <div class="ad__mobilegallery">
            <swiper ref="swiperSimple" class="ad__mobilegallery_carousel" :options="swiperOptions" :pagination="{ clickable: true }" :controls="true" :indicators="false" @click="sliderClicked" v-if="myCar && typeof myCar.images !== 'undefined'">
                <swiper-slide v-for="(image) in this.myCar.images" :key="image.primaryURL.xl" v-touch:swipe="sliderClicked">
                     <div v-if="typeof image.hasVideo !== 'undefined'" :style="getUrl(image)" class="swiper-lazy">
                         <img v-if="typeof image.hasVideo !== 'undefined'"  :data-src="require('../../assets/img/image-ad-transparent.png')" width="414" height="311" alt="video image" data-class="opengallery" data-gallery-index="0" class="swiper-lazy" style="width: inherit" @click="openImageGallery"/>
                     </div>
                     <img v-if="typeof image.hasVideo === 'undefined'"  :data-src="getPrimaryImage(image.primaryURL.xl)" data-class="opengallery" data-gallery-index="0"  width="360" height="221" style="width: 100%" class="swiper-lazy" @click="openImageGallery"/>
                     <div class="swiper-lazy-preloader" id="swiper-lazy-preloader"></div>
                </swiper-slide>
                <div class="ad__mobilegallery_imagecount" slot="pagination"></div>
                <div class="ad__mobilegallery_video"><a href="#" data-class="openvideo" :data-video-url="videoUrl"></a></div>

                <div class="swiper-button-prev" slot="button-prev" @click="checkIframe"></div>
                <div class="swiper-button-next" slot="button-next" @click="checkIframe"></div>
                <div class="player" id="playerSimple"  slot="button-next" @click="playVideo"><span class="youtube-play"></span></div>
            </swiper>

            <div class="empty-mobile-gallery-placeholder" v-if="typeof myCar.images !== 'undefined'  && myCar.images.length == 0">
                <img src="../../assets/img/photos/missing/medium.jpg" width="414" height="311" alt="gallery placeholder">
            </div>

            <div v-if="isVideoAvailable" class="ad__mobilegallery_video"><a data-class="openvideo" :data-video-url="videoUrl" @click="openVideoModal"></a></div>
        </div>

        <div class="ad__desktopgallery">
            <div class="dg1 ad__desktopgallery_left" @click="openImageGallery" :style="mainImageUrl">
                <!--<img :src="mainImageUrl" alt="" data-class="opengallery" data-gallery-index="0" @click="openImageGallery"/>-->
            </div>
            <div class="dg1 ad__desktopgallery_right_img" @click="openVideoModal" :style="image1Url">
                <!--<div class="video-thumbnail" :style="image1Url" @click="openVideoModal"></div>-->
                <div v-if="isVideoAvailable" class="video-thumbnail_play" @click="openVideoModal"/>
            </div>
            <div class="dg1 ad__desktopgallery_right_img ad__desktopgallery_right_img--right" @click="openImageGallery" :style="image2Url">
                <!--<img :src="image2Url" alt="" data-class="opengallery" data-gallery-index="5" @click="openImageGallery"/>-->
                <ul>
                    <li><a href="https://www.addtoany.com/share" :data-a2a-url="adShortLink" class="a2a_dd ad__desktopgallery_link ad__desktopgallery_link--share">Share</a></li>
                    <li><a class="ad__desktopgallery_link" v-on:click.stop="likeAdd(car.id)" :class="liked ? 'ad__desktopgallery_link--saved' : 'ad__desktopgallery_link--save'">Save</a></li>
                </ul>
            </div>
            <div class="dg1 ad__desktopgallery_right_img" @click="openImageGallery" :style="image3Url">
                <!--<img :src="image3Url" alt="" data-class="opengallery" data-gallery-index="6" @click="openImageGallery"/>-->
            </div>
            <div class="dg1 ad__desktopgallery_right_img ad__desktopgallery_right_img--right ad__desktopgallery_right_img--alignbottom"  @click="openImageGallery" :style="image4Url">
                <!--<img :src="image4Url" alt="" data-class="opengallery" data-gallery-index="3" @click="openImageGallery"/>-->
                <ul v-if="typeof car.images !== 'undefined' && car.images.length > 0">
                    <li><a class="ad__desktopgallery_link ad__desktopgallery_link--photos" data-class="opengallery" data-gallery-index="0" @click="openImageGallery">View Photos</a></li>
                </ul>
            </div>
        </div>
        <input type="hidden" id="totalPics" :value="totalImages">
        <svg height="0" width="0">
            <filter id='shadow' color-interpolation-filters="sRGB">
                <feDropShadow dx="2" dy="2" stdDeviation="3" flood-opacity="0.5"/>
            </filter>
        </svg>
    </div>
</template>

<script>
    import Vue from 'vue'
    import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
    import '../../../node_modules/swiper/css/swiper.css'
    import Vue2TouchEvents from 'vue2-touch-events'
    import EventBus from "../../event-bus";
    import $ from "jquery";

    Vue.use(Vue2TouchEvents);

    export default {
        name: "AdGalleries",
        props: ['car', 'advertising'],

        components: {
            Swiper,
            SwiperSlide,
        },

        data () {
            return {
                showSwiper:true,
                likes: [],
                liked: false,
                self: this,
                hasVideo:false,
                galleryAppended: false,
                swiperSlides: this.myCar && typeof this.myCar.images !== 'undefined' && Array.isArray(this.myCar.images) ? this.myCar.images.slice(0, 2) : [],
                swiperOptions: {
                    loop: true,
                    slidesPerView: 'auto',
                    centeredSlides: true,
                    spaceBetween: 30,
                    preloadImages: false,
                    lazy: {
                        loadPrevNext: true
                    },
                    autoHeight: false,
                    pagination: {
                        el: '.ad__mobilegallery_imagecount',
                        type: 'custom',
                        renderCustom: function (swiper, current){
                            return current + ' of ' + document.getElementById("totalPics").value;
                        },
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    },
                }
            }
        },
        mounted(){
            this.loadLikes();
            this.checkLickedAd();
            this.startAddToAnySharing();
        },
        methods: {
            getPrimaryImage(path){
                if(path == null || path.length == 0 || path.endsWith('/advertising/')){
                    return require('../../assets/img/photos/missing/large.jpg')
                }else{
                    return this.car.dealer.id == 1 ? path + '?v='+ Math.floor(Date.now() / 1000) : path;
                }

            },
            getUrl(obj) {
                let url = obj.primaryURL.xl != null ? obj.primaryURL.xl : require('../../assets/img/photos/missing/large.jpg');
                return "background-image: url('" + url + "');background-size: cover;background-repeat: no-repeat; background-position-x: center; width: 100%; height:100%; position:absolut; top:0;";
            },
            startAddToAnySharing() {
                let myScript = document.createElement('script');
                let data = "a2a.init_all();";
                myScript.appendChild(document.createTextNode(data));
                setTimeout(function () {
                    document.body.appendChild(myScript)
                }, 1000);
            },
            sliderClicked: function () {
                if(this.galleryAppended == false){
                    this.appendSlides();
                }
            },
            appendSlides() {
                if(this.myCar && this.myCar.images.length > 2) {
                    if(this.myCar != null && this.myCar.images != null ){
                        if (this.isVideoAvailable) {
                            let video = {
                                'primaryURL' : {'xl' : this.getVideoImage() },
                                'hasVideo' : true
                            };
                            this.swiperSlides.push(video);
                        }
                        this.swiperSlides.push(...this.myCar.images.slice(2));
                    }
                }
                this.galleryAppended = true;

                let that = this;
                this.$refs.swiperSimple.$swiper.on('slideChange', function() {
                    let offset = that.$refs.swiperSimple.$swiper.activeIndex - 2 ;
                    if( offset < 0 || offset >= that.swiperSlides.length ){
                        offset = that.swiperSlides.length - 1;
                    }
                    let content = that.swiperSlides[offset];
                    let element = document.getElementById("playerSimple");
                    if(content && typeof content.hasVideo !== "undefined" && element != null){
                        element.classList.add("hasVideo");
                    }else if( element != null ){
                        element.classList.remove("hasVideo");
                    }
                });

            },
            openImageGallery() {
                if(this.myCar.images.length > 0){
                    EventBus.$emit('openImageGallery');
                }
            },
            getVideoImage(){
                let url;
                if (this.isVideoAvailable) {
                    url = process.env.VUE_APP_API_URL + "/api/image-fetch/" + this.myCar.youtube_id;
                }
                return url;
            },
            openVideoModal() {
                if (this.isVideoAvailable) {
                    EventBus.$emit('openVideoModal');
                } else {
                    if(typeof this.myCar.images !== 'undefined' && this.myCar.images.length > 0) {
                        EventBus.$emit('openImageGallery');
                    }
                }
            },

            loadLikes(){
                this.likes = localStorage.getItem('likes') != null ? JSON.parse(localStorage.getItem('likes')) : [];
            },

            likeAdd(adId){
                this.loadLikes();

                if(!this.likes.includes(adId)){
                    this.likes.push(adId);
                }else{
                    this.likes = this.likes.filter(element => element != adId);
                }
                localStorage.setItem('likes', JSON.stringify(this.likes));
                this.checkLickedAd();
            },
            checkLickedAd(){
                if(this.likes.includes(this.myCar.id)){
                    this.liked = true;
                }else{
                    this.liked = false;
                }
            },
            playVideo(){

                let element = document.getElementsByClassName("swiper-slide-active");
                element[0].childNodes[0].style.display='none';
                let node = document.createElement("iframe");
                node.src = this.formattedYoutubeUrl;
                node.className = "youtube-video video-iframe";

                let containerNode = document.createElement("div");
                containerNode.className = "resp-container";
                containerNode.appendChild(node);
                element[0].appendChild(containerNode);

                let player = document.getElementById("playerSimple");
                player.classList.remove("hasVideo");

                let spinner = document.getElementById('swiper-lazy-preloader');
                spinner.style.display = 'none';

                document.getElementsByClassName('ad__mobilegallery_imagecount')[0].style.display = 'none';
                document.getElementsByClassName('ad__mobilegallery_video')[1].style.display='none'

                //Autoplay is no longer working. commented for now.
                // setTimeout(function(){
                //$('.swiper-slide-active').find('.youtube-video')[0].contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
                // }, 500);

            },
            checkIframe(){
                $('.swiper-slide-active').find('.resp-container').remove();
                let active = document.getElementsByClassName("swiper-slide-active");
                active[0].children[0].style.display='block';
                document.getElementsByClassName('ad__mobilegallery_imagecount')[0].style.display = 'block';
                if(document.getElementsByClassName('ad__mobilegallery_video')[1]){
                    document.getElementsByClassName('ad__mobilegallery_video')[1].style.display='block';
                }
            }
        },
        computed:{
            adShortLink() {
                let link = process.env.VUE_APP_BASE_URL + '/' + this.car.id;
                if (typeof window !== 'undefined') {
                    link = window.location.origin + '/' + this.car.id;
                }
                return link;
            },
            imgVersion(){
              return this.advertising ? '?v=' + Math.floor(Date.now() / 1000) : '';
            },
            myCar(){
                return this.car;
            },
            videoUrl() {
                return  'https://youtube.com/embed/' + this.car.youtube_id;
            },
            getGalleryImages() {
                let arr = [];
                let maxSlice = this.myCar.images.length >= 2 ? 2 : this.myCar.images.length;
                arr.push(...this.myCar.images.slice(0, maxSlice));

                if (this.isVideoAvailable) {
                    let video = {
                        'primaryURL': {'xl': this.getVideoImage()},
                        'hasVideo' : true
                    };
                    arr.push(video);
                }

                if (this.myCar.images.length > 2) {
                    arr.push(...this.myCar.images.slice(2));
                }

                return arr;
            },
            totalImages(){
                let videoCount = this.isVideoAvailable ? 1 : 0;
                return this.myCar && typeof this.myCar.images !== 'undefined' ? this.myCar.images.length + videoCount : 0;
            },

            isVideoAvailable() {
                return this.car.youtube_id != null ? true : false;
            },
            mainImageUrl() {
                // return this.car.images.length > 0 && this.car.images[0].primaryURL.xl != null ? this.car.images[0].primaryURL.xl : require('../../assets/img/photos/missing/large.jpg');
                let url = typeof this.myCar.images !== 'undefined' && this.myCar.images.length > 0 && this.myCar.images[0].primaryURL.xl != null ? this.myCar.images[0].primaryURL.xl + this.imgVersion : require('../../assets/img/photos/missing/large.jpg');
                return "background-image: url('" + url + "');background-size: contain;background-repeat: no-repeat; width: 100%; background-position: top right;";
            },

            image1Url() {
                let url;
                if (this.isVideoAvailable) {
                    url = process.env.VUE_APP_API_URL + "/api/image-fetch/" + this.myCar.youtube_id;
                } else {
                    url = typeof this.myCar.images !== 'undefined' && this.myCar.images.length > 1 && this.myCar.images[1].primaryURL.large != null && !this.myCar.images[1].primaryURL.large.endsWith('/advertising/') ? this.myCar.images[1].primaryURL.large + this.imgVersion : require('../../assets/img/photos/missing/medium.jpg');
                }
                let res =  "background-image: url('" + url + "');margin-top: 0px;";

                if(this.isVideoAvailable){

                    res += "background-size: cover;border-right: 1px solid white;box-shadow: inset 2px 0 0 0px white";
                }
                return res;
            },

            image2Url() {
                let url;
                if (this.isVideoAvailable) {
                    url = typeof this.myCar.images !== 'undefined' && this.myCar.images.length > 1 && this.myCar.images[1].primaryURL.large != null && !this.myCar.images[1].primaryURL.large.endsWith('/advertising/') ? this.myCar.images[1].primaryURL.large + this.imgVersion : require('../../assets/img/photos/missing/medium.jpg');
                } else {
                    url = typeof this.myCar.images !== 'undefined' &&  this.myCar.images.length > 2 && this.myCar.images[2].primaryURL.large != null && !this.myCar.images[2].primaryURL.large.endsWith('/advertising/') ? this.myCar.images[2].primaryURL.large + this.imgVersion : require('../../assets/img/photos/missing/medium.jpg');
                }
                return "background-image: url('" + url + "');background-size: contain;background-repeat: no-repeat; width: 100%;";
            },

            image3Url() {
                let url;
                if (this.isVideoAvailable) {
                    url = typeof this.myCar.images !== 'undefined' &&  this.myCar.images.length > 2 && this.myCar.images[2].primaryURL.large != null && !this.myCar.images[2].primaryURL.large.endsWith('/advertising/') ? this.myCar.images[2].primaryURL.large + this.imgVersion : require('../../assets/img/photos/missing/medium.jpg');
                } else {
                    url = typeof this.myCar.images !== 'undefined' &&  this.myCar.images.length > 3 && this.myCar.images[3].primaryURL.large != null && !this.myCar.images[3].primaryURL.large.endsWith('/advertising/') ? this.myCar.images[3].primaryURL.large + this.imgVersion : require('../../assets/img/photos/missing/medium.jpg');
                }
                return "background-image: url('" + url + "');background-size: contain;background-repeat: no-repeat;";
            },

            image4Url() {
                let url;
                if (this.isVideoAvailable) {
                    url = typeof this.myCar.images !== 'undefined' &&  this.myCar.images.length > 3 && this.myCar.images[3].primaryURL.large != null && !this.myCar.images[3].primaryURL.large.endsWith('/advertising/') ? this.myCar.images[3].primaryURL.large + this.imgVersion : require('../../assets/img/photos/missing/medium.jpg');
                } else {
                    url = typeof this.myCar.images !== 'undefined' &&  this.myCar.images.length > 4 && this.myCar.images[4].primaryURL.large != null && !this.myCar.images[4].primaryURL.large.endsWith('/advertising/') ? this.myCar.images[4].primaryURL.large + this.imgVersion: require('../../assets/img/photos/missing/medium.jpg');
                }
                return "background-image: url('" + url + "');background-size: contain;background-repeat: no-repeat;";
            },
            formattedYoutubeUrl() {
                return 'https://www.youtube.com/embed/' + this.myCar.youtube_id + '?autoplay=0&modestbranding=1&wmode=opaque&rel=0&showinfo=1&enablejsapi=1&controls=1&version=3&playerapiid=ytplayer';
            },
        }
    }
</script>

<style scoped>
</style>
