<template>
    <div class="videomodal" data-id="videomodal" style="display: flex">

        <div class="menutoggle open" data-class="closevideo" @click="closeVideoModal">
            <span></span>
            <span></span>
            <span></span>
        </div>

        <div class="videomodal__wrapper">
            <div class="videomodal__mediawrapper">
                <div class="videomodal__close videomodal__close--desktop" data-class="closevideo" @click="closeVideoModal"></div>
                <div class="media">
                    <iframe width="420" height="315" :src="formattedYoutubeUrl" data-id="modal-video"></iframe>
                </div>
                <p>{{ videoTitle }}</p>
            </div>
        </div>

    </div>
</template>

<script>
    import EventBus from "../../event-bus";

    export default {
        name: "VideoModal",
        props: ['car'],
        data: function () {
            return {
                videoTitle: null
            }
        },
        mounted() {
            this.fetchYoutubeApi();
        },
        computed: {
            formattedYoutubeUrl() {
                return 'https://www.youtube.com/embed/' + this.car.youtube_id;
            },
        },

        methods: {
            closeVideoModal() {
                EventBus.$emit('closeVideoModal');
            },
            fetchYoutubeApi(){
                if(this.car.youtube_id != null){
                    let url = "https://www.googleapis.com/youtube/v3/videos?id="+this.car.youtube_id+"&key="+process.env.VUE_APP_GOOGLE_API_KEY+"&part=snippet";
                    this.axios.get(url)
                        .then(response => {
                            this.videoTitle = response.data ? response.data.items[0].snippet.title : '';
                        })
                        .catch(error => this.errors.record(error.response.data));
                }
            }
        }
    }
</script>

<style scoped>

</style>
